
export enum ActionType {
    SET_MARKET_INFO,
    FETCH_MARKET_TRADE_HISTORY,
    FETCH_MARKET_ORDERS,
    FETCH_CURRENCIES,
    FETCH_BALANCES,
    SIGNALR_UPDATE_BALANCES,
    // FETCH_USER_ORDERS,
    CANCEL_USER_ORDER,
    SET_AVERAGE_COLOR,
    UPDATE_MARKET_ORDERS,
    FLUSH_ORDERBOOK,
    SET_NONCE,

    UPDATE_PARAMETER_SESSIONLIFETIME,

    SET_TERMINAL_HUBCONNECTION,

    SET_PRIVATE_HUBCONNECTION,

    SET_TRADINGVIEW,
    FETCH_TICKERS,

    BLUR_MARKET,

    SET_ACCOUNT_SETTINGS,
    FLUSH_ACCOUNT_SETTINGS,

    SET_TWOSTEPTYPE,

    ADD_ORDER,

    SET_MARKET_TYPE,

    UPDATE_TICKER_and_QUOTE,


    SET_globalPairName,
    SET_lastPrice
}
