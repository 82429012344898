import * as React from 'react';

import { ApiGetOrders, OrderTypes, TradeType } from '../../api-wrapper/api';
import { ChartingLibraryWidgetOptions, IChartingLibraryWidget, LanguageCode } from '../charting_library/charting_library';
import { useCallback, useEffect, useState } from 'react';

import { API_ENDPOINT } from '../../API';
import { BFPortlet } from '../html/BFPortlet';
import { BitflexOpenApi } from '../../_helpers/BitflexOpenApi';
import { Store } from '../../store';
import { widget } from '../charting_library/charting_library.esm';

export function TVChartContainer({
    symbol,
    orders, dispatch_myOrders,
    myOrdersClosed, dispatch_myOrdersClosed,
}: {
    symbol: string,
    orders: Array<ApiGetOrders>, dispatch_myOrders: React.Dispatch<{ type: any; value: any }>,
    myOrdersClosed: Array<ApiGetOrders>, dispatch_myOrdersClosed: React.Dispatch<{ type: any; value: any }>,
}) {
    const {
        state: {
            globalPairName,
            lastPrice
        } } = React.useContext(Store);

    const [chartWidget2, setchartWidget] = useState<IChartingLibraryWidget>();

    const [isLoading, setisLoading] = useState(true);

    const RenderChartPrimitives = useCallback((chartWidget: IChartingLibraryWidget) => {
        if (!orders) return;

        try {
            orders.forEach(order => {
                if (order.orderType === OrderTypes.Market) return;

                if (!order.isMargin) {
                    var orderChart = chartWidget.chart();//.activeChart();

                    orderChart.createOrderLine({})
                        .onCancel(() => {
                            // orderChart.remove();
                            BitflexOpenApi.OrdersApi.apiVversionOrdersCancelPost("1.0", order!.id!);
                        })
                        .setPrice(order.price!)
                        .setText(order?.tradeType + ' ' + order!.price!.toFixed(8))
                        .setLineLength(2)
                        .setBodyBackgroundColor(order.tradeType! === TradeType.Buy ? 'rgb(30, 233, 149)' : '#E03C2D')
                        .setBodyBorderColor(order.tradeType! === TradeType.Buy ? '#28805c' : '#bc5450')
                        .setBodyTextColor("#FFF")
                        .setQuantity(order.amountLeft!.toFixed(8));
                } else {
                    var position = chartWidget.chart();

                    position.createPositionLine({})
                        .onModify(function () {
                            alert("modify")
                        })
                        .onReverse("onReverse called", function (text) {
                            alert("onReverse")
                        })
                        .onClose(order!.id!, function (id: number) {
                            // position.remove();
                            BitflexOpenApi.OrdersApi.apiVversionOrdersCancelPost("1.0", id);
                        })
                        .setText("P&L: 0.0%")
                        .setQuantity(order.amountLeft!.toFixed(8))
                        .setPrice(order.price!)
                        .setExtendLeft(false)
                        .setLineStyle(0)
                        .setLineLength(25);
                }
            });
        }
        catch {
            console.warn("RenderChartPrimitives, Seems Graph is null")
        }
    }, [orders])

    useEffect(() => {
        if (!orders || !myOrdersClosed) return;

        if (chartWidget2) {
            chartWidget2?.activeChart()?.setSymbol(symbol, () => { });

            console.log("SYMBOL CHANGED: ", symbol)

        } else {
            const widgetOptions: ChartingLibraryWidgetOptions = {
                symbol: symbol,
                debug: true,
                // BEWARE: no trailing slash is expected in feed URL
                // tslint:disable-next-line:no-any
                datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(API_ENDPOINT + '/api/tradingview', 30000),
                interval: '1' as ChartingLibraryWidgetOptions['interval'],
                container_id: 'tv_chart_container',
                library_path: '/charting_library/',
                toolbar_bg: '#141821',
                locale: getLanguageFromURL() || 'en',
                disabled_features: ['use_localstorage_for_settings'],
                enabled_features: ['study_templates'],
                charts_storage_url: 'https://saveload.tradingview.com',
                charts_storage_api_version: '1.1',
                client_id: 'tradingview.com',
                user_id: 'public_user_id',
                fullscreen: false,
                autosize: true,
                theme: 'Dark',
                loading_screen: { backgroundColor: "#141821", foregroundColor: "#ffd700" },
                overrides: {
                    "paneProperties.background": "#14181f",

                    'mainSeriesProperties.candleStyle.upColor': 'rgb(30, 233, 149)',
					'mainSeriesProperties.candleStyle.downColor': 'rgb(248, 73, 96)',
					'mainSeriesProperties.candleStyle.drawWick': true,
					'mainSeriesProperties.candleStyle.drawBorder': true,
					'mainSeriesProperties.candleStyle.borderColor': 'rgb(30, 233, 149)',
					'mainSeriesProperties.candleStyle.borderUpColor': 'rgb(30, 233, 149)',
					'mainSeriesProperties.candleStyle.borderDownColor': 'rgb(248, 73, 96)',
					// 'mainSeriesProperties.candleStyle.wickColor': '#737375',
					'mainSeriesProperties.candleStyle.wickUpColor': 'rgb(30, 233, 149)',
					'mainSeriesProperties.candleStyle.wickDownColor': 'rgb(248, 73, 96)',
                }
            };

            var tvWidget = new widget(widgetOptions);

            tvWidget.onChartReady((t) => {
                setchartWidget(tvWidget)
                RenderChartPrimitives(tvWidget)
                setisLoading(false)
            })
        }
    }, [chartWidget2, symbol, orders, myOrdersClosed, RenderChartPrimitives]);

    return (
        <BFPortlet title={'Chart'} isLoading={isLoading} noHeader={true} isScrollable={false}>
            <div style={{ height: '100%', paddingLeft: 0, paddingRight: 0, position: 'relative' }} >
                <div className={'chart-watermark'}>
                    <div>{globalPairName} | {lastPrice?.toFixed(6)}</div>
                    <div> BCFLEX Exchange</div>
                </div>
                <div
                    id={'tv_chart_container'}
                    className={'TVChartContainer'}
                />
            </div>
        </BFPortlet>
    );
}

function getLanguageFromURL(): LanguageCode | null {
    const regex = new RegExp('[\\?&]lang=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
}